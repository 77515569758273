import { InputAdornment } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import numeral from "numeral";
import { useState } from "react";

export function MoneyInput({ value, hiddenLabel = false, ...props }) {
  const [focus, setFocus] = useState(false);
  return (
    <MDInput
      {...props}
      type={focus ? "number" : "text"}
      value={focus ? value : numeral(value).format("0,0")}
      onFocus={(e) => {
        setFocus(true);
        props.onFocus?.(e);
      }}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MDTypography variant="button" color="black">
              $
            </MDTypography>
          </InputAdornment>
        ),
      }}
      placeholder="0"
      onBlur={(e) => {
        setFocus(false);
        props.onBlur?.(e);
      }}
      sx={{
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& .MuiFormLabel-root": {
          opacity: hiddenLabel ? 0 : 1,
        },
      }}
    />
  );
}
